/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { AuditLogSearchRequest, AuditLogSearchResponse } from '@ws1c/intelligence-models';

/**
 * Provide access to Report Activities/Audit Log Information
 * This is READ ONLY information
 * @link ReportMetaController.java
 * @link AuditLogController.java
 * @link AuditLogController#getAuditLogs
 * @link AuditLogController#getAuditLogsForReport
 * @export
 * @class AuditLogService
 */
@Injectable()
export class AuditLogService {
  /**
   * Creates an instance of AuditLogService.
   * @param {HttpService} http
   * @memberof AuditLogService
   */
  constructor(private http: HttpService) {}

  /**
   * getAuditLogs
   * @param {AuditLogSearchRequest} searchRequest
   * @returns {Observable<AuditLogSearchResponse>}
   * @memberof AuditLogService
   */
  public getAuditLogs(searchRequest: AuditLogSearchRequest): Observable<AuditLogSearchResponse | any> {
    return this.http.post(Endpoint.ACTIVITY_ROOT, searchRequest).pipe(
      map((response: any) => deserialize(AuditLogSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAuditLogsForReport
   * @param {AuditLogSearchRequest} searchRequest
   * @returns {Observable<AuditLogSearchResponse>}
   * @memberof AuditLogService
   */
  public getAuditLogsForReport(searchRequest: AuditLogSearchRequest): Observable<AuditLogSearchResponse | any> {
    return this.http.post(Endpoint.REPORT_ACTIVITY(searchRequest.reportId), searchRequest).pipe(
      map((response: any) => deserialize(AuditLogSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}
